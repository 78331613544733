@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: "Archivo", sans-serif;
  scroll-behavior: smooth !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 3px;
}

.scrollHide::-webkit-scrollbar {
  width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c2c28;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.quoteHoverMain:hover .hoverInnerChangeClass {
  background: #332622 !important;
  color: white !important;
}

.hoverStarDark svg {
  fill: #3c2c28 !important;
}

.hoverStarLight svg {
  fill: #cac4c1 !important;
}

.slick-prev:before,
.slick-next:before {
  color: #3c2c28 !important;
  font-size: 30px !important;
}

.play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 80px !important;
  height: 80px !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: none !important;
  border-radius: 50% !important;
  outline: none !important;
}

.play-button::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: 30px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #ffffff;
}

.react-player {
  width: 100% !important;
  height: auto !important;
}

.react-player video {
  border-radius: 1rem !important;
}
input[type='radio']{
/* width: 25px;
height: 25px; */
  accent-color: #3C2C28;
  padding: 5px; 
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #3C2C28;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
@media (max-width: 500px) {
  .slick-next {
    right: -15px !important;
  }
}

.normal_HTML ul {
  list-style: disc;
  padding-left: 20px;
}

.normal_HTML ol {
  list-style: decimal;
  padding-left: 20px;
}

.normal_HTML h3 {
  font-weight: 600;
}
@media (max-width: 1024px){
  .dropdown{
    margin-left: -210px;
  }
}
@media (max-width: 768px){
  .dropdown{
    margin-left: -102px !important; 
  }
}
@media (max-width: 500px){
  .dropdown{
    margin-left: -46px !important;
  }
}
@media (max-width: 400px){
  .dropdown{
    margin-left: -46px !important;
  }
}